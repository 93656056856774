import React from "react";
import ReactDOM from "react-dom/client";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { arbitrum, mainnet, polygon, goerli } from "wagmi/chains";
import { WagmiConfig } from "wagmi";
import "rc-table/assets/index.css";
import "rc-tabs/assets/index.css";
import "rc-dialog/assets/index.css";
import "rc-checkbox/assets/index.css";
import "react-toastify/dist/ReactToastify.css";
import "@reach/menu-button/styles.css";
import "./font/index.css";
import "./index.css";
import App from './App';

const projectId = "a1446779ea9ee83f7b3fa091e678f90d";

const chains = [arbitrum, mainnet, polygon, goerli];

const wagmiConfig = defaultWagmiConfig({ chains, projectId });

createWeb3Modal({
  wagmiConfig,
  projectId,
  featuredWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa",
    "20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66",
  ],
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WagmiConfig config={wagmiConfig}>
    <App />
  </WagmiConfig>
);
