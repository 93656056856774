import { useState } from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeaderDrawer from '../components/HeaderDrawer';
import BgPng from '../assets/1.png';
import BgPng1 from '../assets/2.png';

const LayoutWrapper = styled.div`
  background: url(${BgPng});
  background-repeat: no-repeat;
  background-size: cover;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 24px 16px;
  }
`;

export default function Layout() {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    setVisible(!visible);
  };

  return (
    <LayoutWrapper data-path={window.location.pathname}>
      <Header visible={visible} toggleVisible={toggleVisible} />
      {
        visible ? 
        <HeaderDrawer visible={visible} toggleVisible={toggleVisible} />
        : (
          <ContentContainer>
            <ContentWrapper>
              <Outlet />
            </ContentWrapper>
          </ContentContainer>
        )
      }
      <Footer />
    </LayoutWrapper>
  );
}