import { useState, useEffect } from "react";
import Big from "big.js";
import { useAccount } from "wagmi";
import { page_size, decimals } from "../config";
import {
  Container,
  Card,
  CardItem,
  CardLabel,
  CardValue,
  CardButton,
  Content,
  CardImgWrapper,
  CardImg,
  ListButton,
  ButtonWrapper,
  UnitValue,
  PriceValue,
  FloorPriceWrapper,
} from "./ListStyled";
import { getAddress, notify } from "../utils";
import { useAllOrders } from "../hooks/useQuery";
import CardPng from "../assets/card-img.jpeg";
import Pagination from "../components/Pagination";
import { buyOrder } from "../services";
import { useMutation } from "react-query";
import Dialog from "rc-dialog";
import useFloorPrice from '../hooks/useFloorPrice';

export default function MyOrders() {
  const { address } = useAccount();
  const [page, setPage] = useState(1);
  const data = useAllOrders(page);
  const { mutate, isLoading, isError, isSuccess } = useMutation(buyOrder);
  const [currentItem, setItem] = useState({});
  const [visible, setVisible] = useState(false);
  const [index, setIndex] = useState();
  const floorPrice = useFloorPrice();

  const price = (amount) =>
    amount ? new Big(Number(amount)).div(decimals).toString() : 0;

  const unitPrice = (amount, num) =>
    new Big(Number(amount)).div(num).div(decimals).toFixed(8).toString();

  const isMine = (owner) =>
    String(owner).toLowerCase() === String(address).toLowerCase();

  const handleOpen = (item, idx) => {
    setIndex(idx);
    setItem(item);
    setVisible(true);
  };

  const handleConfirm = () => {
    if (isLoading) return;
    mutate({
      orderId: currentItem.orderId,
      amount: currentItem.amount,
      address,
    });
  };

  const handleCancel = () => {
    setVisible(false);
    setItem({});
  };

  useEffect(() => {
    if (isSuccess) {
      notify("Transaction Success.");
      setVisible(false);
      setItem({});
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      notify("Transaction Failed.");
    }
  }, [isError]);

  return (
    <Container>
      <FloorPriceWrapper>floor price: {floorPrice} kcs/inscription</FloorPriceWrapper>
      <Content>
        {data[0].map((item, idx) => (
          <Card key={idx}>
            <CardItem>
              <CardLabel>order</CardLabel>
              <CardValue>#{idx}</CardValue>
            </CardItem>
            <CardImgWrapper>
              <CardImg src={CardPng} />
            </CardImgWrapper>
            <CardItem>
              <CardLabel>total</CardLabel>
              <CardValue>
                {item.ids.length} Inscription{item.ids.length > 1 ? "s" : ""}
              </CardValue>
            </CardItem>
            <CardItem>
              <CardLabel>price</CardLabel>
              <PriceValue>
                {price(item.amount)}kcs
                <UnitValue>
                  {Number(unitPrice(item.amount, item.ids.length))}
                  kcs/inscription
                </UnitValue>
              </PriceValue>
            </CardItem>
            <CardItem>
              <CardLabel>owner</CardLabel>
              <CardValue>{getAddress(item.owner)}</CardValue>
            </CardItem>
            <CardItem>
              <CardButton
                onClick={() => handleOpen(item, idx)}
                disabled={isMine(item.owner)}
              >
                Buy{isMine(item.owner) && " (mine)"}
              </CardButton>
            </CardItem>
          </Card>
        ))}
      </Content>
      <Pagination
        total={Number(data[1])}
        current={page}
        onChange={(page) => setPage(page)}
        pageSize={page_size}
      />

      <Dialog
        width={400}
        title="List"
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <CardItem>
          <CardLabel>order</CardLabel>
          <CardValue>#{index}</CardValue>
        </CardItem>
        <CardItem>
          <CardLabel>Cost</CardLabel>
          <CardValue>{price(currentItem.amount)} kcs</CardValue>
        </CardItem>
        <ButtonWrapper>
          <ListButton onClick={handleCancel}>Cancel</ListButton>
          <ListButton onClick={handleConfirm} disabled={isLoading}>
            {isLoading ? "Creating..." : "Ok"}
          </ListButton>
        </ButtonWrapper>
      </Dialog>
    </Container>
  );
}
