import { useState, useEffect } from "react";
import styled from "styled-components";
import {
  CardItem,
  CardLabel,
  CardValue,
  ButtonWrapper,
  ListButton,
  ListInput,
  CardTip,
} from "./ListStyled";
import { notify } from "../utils";
import { batchSafeMint } from "../services";
import Dialog from "rc-dialog";
import { useMutation } from "react-query";
import Big from 'big.js';
import { useLockedInscriptions, useMintPossible } from "../hooks/useQuery";
import { mint_fee } from "../config";
import { useAccount } from "wagmi";

const MintWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 24px 0 48px;
`;

const LockedTitle = styled.div`
  font-size: 24px;
  display: flex;
  align-items: center;
`;

const CMintPossible = styled.div`
  width: 100%;
  display: flex;
  padding: 12px;
  border-radius: 8px;
  font-size: 12px;
  background: #c3c3bb;
  color: #000;
`;

export default function BatchMint() {
  const mintPossible = useMintPossible();
  const data = useLockedInscriptions(mintPossible);
  const [visible, setVisible] = useState(false);
  const [num, setNum] = useState(0);
  const { address } = useAccount();
  const { mutate, isLoading, isError, isSuccess } = useMutation(batchSafeMint);
  
  const handleOpen = () => {
    setVisible(true);
  };

  const handleConfirm = () => {
    if (
      num < 0 ||
      num > Number(data) ||
      isLoading ||
      !num ||
      num > 100 ||
      !Number.isInteger(Number(num))
    )
      return;
    mutate({ num, address });
  };

  const handleCancel = () => {
    setVisible(false);
    setNum(0);
  };

  useEffect(() => {
    if (isSuccess) {
      notify("Unlock Success.");
      setVisible(false);
      setNum(0);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      notify("Unlock Success.");
      setVisible(false);
      setNum(0);
      // notify("Unlock Failed.");
    }
  }, [isError]);

  return (
    <MintWrapper>
      <LockedTitle>
        Locked Inscriptions: {Number(data)}
        <ListButton onClick={handleOpen}>Unlock</ListButton>
      </LockedTitle>
      <Dialog
        width={400}
        title="List"
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <CardItem>
          <CardLabel>Unlock</CardLabel>
          <CardValue>
            <ListInput
              type="number"
              value={num}
              min={0}
              onChange={(e) => setNum(e.target.value)}
            />
          </CardValue>
        </CardItem>
        {num > 100 && (
          <CardItem>
            <CardLabel />
            <CardTip>Maximum single unlock limit is 100</CardTip>
          </CardItem>
        )}
        <CardItem>
          <CardLabel>Cost</CardLabel>
          <CardValue>
            {new Big(mint_fee).mul(num || 0).toString()} kcs
          </CardValue>
        </CardItem>
        {!mintPossible && (
          <CardItem>
            <CMintPossible>Currently unable to unlock.</CMintPossible>
          </CardItem>
        )}
        <ButtonWrapper>
          <ListButton onClick={handleCancel}>Cancel</ListButton>
          <ListButton
            onClick={handleConfirm}
            disabled={
              !mintPossible ||
              isLoading ||
              !num ||
              num < 0 ||
              num > Number(data) ||
              num > 100
              || !Number.isInteger(Number(num))
            }
          >
            {isLoading ? "Unlocking..." : "Unlock"}
          </ListButton>
        </ButtonWrapper>
      </Dialog>
    </MintWrapper>
  );
}
