import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Connect from './Connect';
import LogoImg from '../assets/logo.svg';
import TokensImg from '../assets/tokens.svg';
import InscriptionImg from '../assets/inscription.svg';
import DeployImg from '../assets/deploy.svg';
import MyTokenImg from '../assets/my-token.svg';
import TokensImgWhite from '../assets/tokens-white.svg';
import InscriptionImgWhite from '../assets/inscription-white.svg';
import DeployImgWhite from '../assets/deploy-white.svg';
import MyTokenImgWhite from '../assets/my-token-white.svg';
import CloseImg from '../assets/close.svg';
import CloseIconImg from '../assets/close-icon.svg';
import TransferImg from '../assets/transfer.svg';
import DragImg from '../assets/drag.svg';
import NetWork from './NetWork';

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--2-text-100, #1D1D1D);
  background: rgba(255, 255, 255, 0.00);
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 16px;
  }
`;

const Logo = styled.img`
  ${props => props.theme.breakpoints.down('sm')} {
    height: 24px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${props => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const MenuItem = styled.div`
  margin-left: 24px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  font-size: 18px;
  color: #1F1F1F;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.15px;
  border-radius: 8px;
  cursor: pointer;
  &.active {
    color: #fff;
    background: #1F1F1F;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const MenuIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

const Divider = styled.div`
  width: 1px;
  height: 24px;
  margin: 0 40px;
  background: #1F1F1F;
`;

const ActionImg = styled.img`
  width: 32px;
  height: 32px;
  margin-left: 24px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const SmAction = styled.div`
  display: none;
  ${props => props.theme.breakpoints.down('sm')} {
    display: flex;
  }
`;

const CloseXImg = styled.img`
  width: 32px;
  height: 32px;
  margin-left: 12px;
  display: none;
  ${(props) => props.theme.breakpoints.down("sm")} {
    display: block;
  }
`;

const DragIconImg = styled.img`
  width: 32px;
  height: 32px;
  margin-left: 12px;
`;

function Links() {
  const path = window.location.pathname;
  const push = useNavigate();
  return (
    <>
      <MenuItem
        className={clsx({ active: path === "/" })}
        onClick={() => push("/")}
      >
        <MenuIcon src={path === "/" ? TokensImgWhite : TokensImg} />
        Tokens
      </MenuItem>
      <MenuItem
        className={clsx({ active: path === "/inscription" })}
        onClick={() => push("/inscription")}
      >
        <MenuIcon
          src={path === "/inscription" ? InscriptionImgWhite : InscriptionImg}
        />
        Inscription
      </MenuItem>
      <MenuItem
        className={clsx({ active: path === "/market" })}
        onClick={() => push("/market")}
      >
        <MenuIcon src={path === "/market" ? MyTokenImgWhite : MyTokenImg} />
        Market
      </MenuItem>
      {/* <MenuItem className={clsx({ active: path === '/deploy' })} onClick={() => push('/deploy')}>
        <MenuIcon src={path === '/deploy' ? DeployImgWhite : DeployImg} />
        Deploy KCS20
      </MenuItem> */}
      {/* <MenuItem className={clsx({ active: path === '/mine' })} onClick={() => push('/mine')}>
        <MenuIcon src={path === '/mine' ? MyTokenImgWhite : MyTokenImg} />
        My tokens
      </MenuItem> */}
    </>
  );
}

export default function Header({ visible, toggleVisible }) {
  return (
    <Wrapper>
      <Logo src={LogoImg} />     
      <Content>
        <LinkWrapper>
          <Links />
          <Divider />
          <NetWork />
          <Connect />    
        </LinkWrapper>
        <ActionImg src={CloseImg} onClick={() => window.open('https://t.me/kcscription')} />
        <ActionImg src={TransferImg} onClick={() => window.open('https://twitter.com/kcscription')} />
        <SmAction>
          {visible ? (
            <CloseXImg
              src={CloseIconImg}
              onClick={toggleVisible}
            />
          ) : (
            <DragIconImg src={DragImg} onClick={toggleVisible} />
          )}
        </SmAction>
        
      </Content>
    </Wrapper>
  );
}