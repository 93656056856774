import { useEffect, useState } from "react";
import Big from 'big.js';
import { useMutation } from "react-query";
import { batchCancelOrder } from "../services";
import { page_size, decimals} from "../config";
import {
  Container,
  Card,
  CardItem,
  CardLabel,
  CardValue,
  CardButton,
  Content,
  CardImgWrapper,
  CardImg,
  UnitValue,
  PriceValue,
} from "./ListStyled";
import { getAddress, notify } from "../utils";
import { useMyOrders } from "../hooks/useQuery";
import CardPng from "../assets/card-img.jpeg";
import Pagination from "../components/Pagination";
import { useAccount } from "wagmi";

export default function MyOrders() {
  const [page, setPage] = useState(1);
  const data = useMyOrders(page);
  const { address } = useAccount();
  const { mutate, isLoading, isError, isSuccess } =
    useMutation(batchCancelOrder);

  const price = (amount) => amount ? new Big(Number(amount)).div(decimals).toString() : 0;
  const unitPrice = (amount,num) => new Big(Number(amount)).div(num).div(decimals).toFixed(8).toString();

  const cancel = (id) => {
    if (isLoading) return;
    mutate({ ids: [id], address });
  }

  useEffect(() => {
    if (isError) {
      notify('Cancel Failed.');
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      notify("Cancel Success.");
    }
  }, [isSuccess]);

  return (
    <Container>
      <Content>
        {data[0].map((item, idx) => (
          <Card key={idx}>
            <CardItem>
              <CardLabel>order</CardLabel>
              <CardValue>#{idx}</CardValue>
            </CardItem>
            <CardImgWrapper>
              <CardImg src={CardPng} />
            </CardImgWrapper>
            <CardItem>
              <CardLabel>total</CardLabel>
              <CardValue>
                {item.ids.length} Inscription{item.ids.length > 1 ? "s" : ""}
              </CardValue>
            </CardItem>
            <CardItem>
              <CardLabel>price</CardLabel>
              <PriceValue>
                {price(item.amount)}kcs
                <UnitValue>
                  {Number(unitPrice(item.amount, item.ids.length))}
                  kcs/inscription
                </UnitValue>
              </PriceValue>
            </CardItem>
            <CardItem>
              <CardLabel>owner</CardLabel>
              <CardValue>{getAddress(item.owner)}</CardValue>
            </CardItem>
            <CardItem>
              <CardButton onClick={() => cancel(Number(item.orderId))}>
                Cancel
              </CardButton>
            </CardItem>
          </Card>
        ))}
      </Content>
      <Pagination
        total={Number(data[1])}
        current={page}
        onChange={(page) => setPage(page)}
        pageSize={page_size}
      />
    </Container>
  );
}
