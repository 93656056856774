import Big from "big.js";
import { mint_fee } from "../config";
import { Market, Assets, KCSSState, getGasPrice } from "../utils";

export const getMintPossible = (address) => {
  return new Promise((resolve, reject) => {
    KCSSState.methods
      .mintPossible()
      .call({
        from: address,
      })
      .then((data) => resolve(Number(data)))
      .catch(reject);
  });
};

export const batchSafeMint = async ({ num, address }) => {
  const value = String(new Big(mint_fee).mul(num).mul(1e18));
  const gasPrice = await getGasPrice();
  const params = {
    from: address,
    value,
  };
  const gasLimit = await Assets.methods
    .batchSafeMint(num)
    .estimateGas({ ...params, gasPrice });
  return new Promise((resolve, reject) => {
    Assets.methods
      .batchSafeMint(num)
      .send({
        ...params,
        gasLimit,
        gasPrice,
      })
      .then(resolve)
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const getOrdersWithPage = ({ address, page, pageSize }) => {
  return new Promise((resolve, reject) => {
    KCSSState.methods
      .getOrdersWithPage(address, page, pageSize)
      .call({
        from: address,
      })
      .then((data) => {
        const _data = {
          0: data[0].filter((item) => item.isValid),
          1: data[1],
        };
        resolve(_data);
      })
      .catch(reject);
  });
}

export const getNFTsWithPage = ({ address, page, pageSize }) => {
  return new Promise((resolve, reject) => {
    KCSSState.methods
      .getNFTsWithPage(address, page, pageSize)
      .call({
        from: address,
      })
      .then(resolve)
      .catch(reject);
  });
}

export const batchCancelOrder = async ({ ids, address }) => {
  const gasPrice = await getGasPrice();
  const gasLimit = await Market.methods
    .batchCancelOrder(ids)
    .estimateGas({ from: address, gasPrice });
  return new Promise((resolve, reject) => {
    Market.methods
      .batchCancelOrder(ids)
      .send({
        from: address,
        gasLimit,
        gasPrice,
      })
      .then(resolve)
      .catch(reject);
  });
};

export const buyOrder = async (params) => {
  const gasPrice = await getGasPrice();
  return new Promise((resolve, reject) => {
    Market.methods
      .buyOrder(params.orderId)
      .send({
        from: params.address,
        value: params.amount,
        gasPrice,
      })
      .then(resolve)
      .catch(reject);
  });
};

export const batchMakeOrder = async ({ numbers, prices, address }) => {
  const gasPrice = await getGasPrice();
  const gasLimit = await Market.methods
    .batchMakeOrder(numbers, prices)
    .estimateGas({ from: address, gasPrice });

  return new Promise((resolve, reject) => {
    Market.methods
      .batchMakeOrder(numbers, prices)
      .send({
        from: address,
        gasLimit,
        gasPrice,
      })
      .then(resolve)
      .catch(reject);
  });
};

export const makeOrder = async ({ ids, price, address }) => {
  const gasPrice = await getGasPrice();
  const gasLimit = await Market.methods
    .makeOrder(ids, price)
    .estimateGas({ from: address, gasPrice });
  return new Promise((resolve, reject) => {
    Market.methods
      .makeOrder(ids, price)
      .send({
        from: address,
        gasPrice,
        gasLimit,
      })
      .then(resolve)
      .catch(reject);
  });
};

export const lockedInscriptions = (from) => {
  return new Promise((resolve, reject) => {
    KCSSState.methods
      .lockedInscriptions(from)
      .call({
        from,
      })
      .then(resolve)
      .catch(reject);
  });
};

