import RcTabs from 'rc-tabs';
import styled from 'styled-components';

const CusTabs = styled(RcTabs)`
  border: none;
  .rc-tabs-tab {
    margin-right: 24px;
    background: transparent;
  }
  .rc-tabs-ink-bar {
    background: #000;
  }
`;

export default function Tabs(props) {
  return <CusTabs {...props} />;
}