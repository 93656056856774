import { useEffect, useState } from "react";
import {
  CardItem,
  CardLabel,
  CardValue,
  ButtonWrapper,
  ListButton,
  ListInput,
} from "./ListStyled";
import { notify } from "../utils";
import { makeOrder } from "../services";
import Dialog from "rc-dialog";
import { useMutation } from "react-query";
import Big from "big.js";
import useFloorPrice from '../hooks/useFloorPrice';
import { useAccount } from "wagmi";

export default function MakeOrder({ visible, onClose, onSuccess, id }) {
  const { mutate, isLoading, isError, isSuccess } = useMutation(makeOrder);
  const [price, setPrice] = useState(0);
  const floorPrice = useFloorPrice();
  const { address } = useAccount();

  const handleConfirm = () => {
    if (isLoading || !price) return;
    mutate(
      { ids: [id], price: Number(new Big(price).mul(1e18)), address }
    );
  };

  useEffect(() => {
    if (isSuccess) {
      notify("Create Success.");
      onSuccess();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      notify("Create Failed.");
    }
  }, [isError]);

  return (
    <Dialog width={400} title="List" onClose={onClose} visible={visible}>
      <CardItem>
        <CardLabel>floor price</CardLabel>
        <CardValue>{floorPrice} kcs/inscription</CardValue>
      </CardItem>
      <CardItem>
        <CardLabel>index</CardLabel>
        <CardValue>#{id}</CardValue>
      </CardItem>
      <CardItem>
        <CardLabel>Price</CardLabel>
        <CardValue>
          <ListInput
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            type="number"
            min={0}
          />
          kcs
        </CardValue>
      </CardItem>
      <ButtonWrapper>
        <ListButton onClick={onClose}>Cancel</ListButton>
        <ListButton
          onClick={handleConfirm}
          disabled={isLoading || !price || price <= 0}
        >
          {isLoading ? "Creating..." : "Ok"}
        </ListButton>
      </ButtonWrapper>
    </Dialog>
  );
}
