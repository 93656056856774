import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Inscription from "./pages/insp";
import Deploy from "./pages/deploy";
import Layout from "./pages/layout";
import Home from "./pages/index";
import Mine from "./pages/mine";
import Market from './pages/market';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/inscription",
        element: <Inscription />,
      },
      // {
      //   path: "/deploy",
      //   element: <Deploy />,
      // },
      // {
      //   path: "/mine",
      //   element: <Mine />,
      // },
      {
        path: "/market",
        element: <Market />
      }
    ],
  },
]);

const points = {
  sm: 500,
};

const breakpoints = {
  down: (key) => {
    const value = typeof points[key] === "number" ? points[key] : key;
    return `@media (max-width:${value - 1}px)`;
  },
};

const queryClient = new QueryClient();

export default () => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={{ breakpoints }}>
      <RouterProvider router={router} />
      <ToastContainer />
    </ThemeProvider>
  </QueryClientProvider>
);
