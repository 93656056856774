import styled from 'styled-components';
import RcTable from 'rc-table';
import Pagination from './Pagination';
import EmptyImg from '../assets/empty.svg';

const Wrapper = styled.div`
  width: 100%;
  border-radius: 24px;
  border: 1px solid #1F1F1F;
  background: #FFF;
  box-shadow: 2px 2px 0px 4px #1F1F1F;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
`;

const PaginationWrapper = styled.div`
  margin-top: 24px;
`;

const TableEmptyWrapper = styled.div`
  margin-top: 56px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const TableEmpty = styled.img`
  width: 261px;
  height: 247px;
`;

export const TableAction = styled.div`
  padding-right: 12px;
`;

const TableWrapper = styled(RcTable)`
  width: 100%;
  min-height: 1000px;
  &.rc-table th, &.rc-table td {
    border: none;
    background: none;
    text-align: center;
  }
  &.rc-table thead th, &.rc-table thead td {
    padding: 24px 0;
    font-weight: 400;
    color: #1F1F1F;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    border-bottom: 1px solid #1F1F1F;
  }
  &.rc-table tbody td {
    padding: 32px 0;
    color: #1F1F1F;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.15px;
  }
  &.rc-table tbody tr:hover {
    background: #F3F3F3;
  }
  .rc-table-content {
    border: none;
  }
  &.rc-table tbody tr.rc-table-placeholder:hover {
    background: transparent;
  }
`;

export default function Table({ pagination, ...props }) {
  return (
    <Wrapper>
      <TableWrapper {...props} emptyText={
        <TableEmptyWrapper>
          <TableEmpty src={EmptyImg} />
        </TableEmptyWrapper>
      } />
      {/* {
        pagination && (
          <PaginationWrapper>
            <Pagination {...pagination} />
          </PaginationWrapper>
        )
      } */}
    </Wrapper>
  );
}