import { useMemo } from 'react';
import styled from 'styled-components';
import { useAccount, useDisconnect } from 'wagmi';
import { useWeb3Modal } from "@web3modal/wagmi/react";
import {
  Menu,
  MenuList,
  MenuLink,
  MenuButton,
  MenuItem,
} from "@reach/menu-button";
import AccountImg from '../assets/account-logo.svg';

const ConnectButton = styled.div`
  display: flex;
  height: 40px;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: #1F1F1F;
  background: #C8FC7C;
  color: #1F1F1F;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  border: 1px solid #1F1F1F;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  
`;

const AccountLogo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

const CusMenuButton = styled(MenuButton)`
  outline: none;
  border: none;
  display: flex;
  height: 40px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: #1F1F1F;
  background: #C8FC7C;
  color: #1F1F1F;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  border: 1px solid #1F1F1F;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const MenuWrapper = styled.div`
  [data-reach-menu-popover] {
    top: 70px;
    min-width: 240px;
    transform: translateX(-70px);
  }
  ${props => props.theme.breakpoints.down('sm')} {
    margin-top: 12px;
  }
`;

const CusMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
  cursor: unset;
  &[data-selected] {
    background: none !important;
  }
`;

const CusMenuLink = styled(MenuLink)`
  width: 100%;
  margin-top: 12px;
  display: flex;
  align-items: center;
  padding: 12px 28px !important;
  border-radius: 8px;
  border: 1px solid var(--2, #1F1F1F);
  background: var(--1, #FFF);
  font-size: 14px !important;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
`;

export default function Connect() {
  const { isConnected, address } = useAccount();
  const { open, isOpen } = useWeb3Modal();
  // const { connect, connectors } = useConnect();
  const { disconnect } = useDisconnect();
  // const connector = connectors.find(item => item.name === 'MetaMask');

  const handleConnect = () => {
    if (isConnected) return;
    // connect({ connector });
    open();
  }

  const _address = useMemo(() => {
    if (!address) return "";
    const _first = address.slice(0, 6);
    const _last = address.slice(-3);
    return `${_first}...${_last}`;
  }, [address]);

  return !isConnected ? (
    <ConnectButton onClick={handleConnect}>
      {isOpen ? 'Connecting...' : 'Connect Wallet'}
    </ConnectButton>
  ) : (
     <MenuWrapper>
      <Menu>
        <CusMenuButton>
          <AccountLogo src={AccountImg} /> {_address}
        </CusMenuButton>
        <MenuList portal={false}>
          <CusMenuItem>
            <AccountLogo src={AccountImg} /> {_address}
          </CusMenuItem>
          <CusMenuLink onClick={() => disconnect()}>
            Disconnect
          </CusMenuLink>
        </MenuList>
      </Menu>
    </MenuWrapper>
  );
}