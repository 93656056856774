import { useEffect } from 'react';
import styled from 'styled-components';
import { useSwitchNetwork, useNetwork } from "wagmi";
import _ from 'lodash';
import { addMainNet } from '../utils';
import { kcc_chain_id } from '../config';

const ConnectButton = styled.div`
  margin-right: 12px;
  display: flex;
  height: 40px;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: #1F1F1F;
  background: #C8FC7C;
  color: #1F1F1F;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  border: 1px solid #1F1F1F;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

export default function NetWork() {
  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetwork();

  const handleSwitchMain = () => {
    const hasBaseChain = !!_.find(
      chains,
      (chain) => chain.id === kcc_chain_id
    );
    if (!hasBaseChain) {
      addMainNet();
    } else if (chain !== kcc_chain_id) {
      switchNetwork({ chainId: kcc_chain_id });
    }
  }

  return (
    <ConnectButton onClick={handleSwitchMain}>
      { 'KCC Chain' }
    </ConnectButton>
  );
}