import { useEffect, useState } from "react";
import styled from 'styled-components';
import {
  CardItem,
  ButtonWrapper,
  ListButton,
  OrderButton,
  Hr,
  CardValue,
  CardLabel,
  LeftInscriptions,
  CardTip,
} from "./ListStyled";
import { notify } from "../utils";
import { batchMakeOrder } from "../services";
import Dialog from "rc-dialog";
import { useMutation } from "react-query";
import { useMyNFTs } from '../hooks/useQuery';
import { v4 as uuid } from 'uuid';
import Big from "big.js";
import useFloorPrice from "../hooks/useFloorPrice";
import { useAccount } from "wagmi";

const OrderItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const InputContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 26px;
`;

const InputLabel = styled.div`
  font-size: 14px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
  height: 100%;
  width: 200px;
  border: 1px solid #000;
  border-radius: 8px;
  display: flex;
  font-size: 12px;
  background: #fff;
`;

const InputContainer = styled.input`
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
  font-size: 12px;
  text-indent: 12px;
`;

export default function BatchMakeOrder({ visible, onClose, onSuccess }) {
  const floorPrice = useFloorPrice();
  const nfts = useMyNFTs(1);
  const { address } = useAccount();
  const { mutate, isLoading, isError, isSuccess } = useMutation(batchMakeOrder);
  const [orders,setOrders] = useState([{ total: 0, amount: 0, id: uuid() }]);
  const total = orders.reduce(
    (a, b) => Number(new Big(a).add(Number(b.total))),
    0
  );
  const amount = orders.reduce(
    (a, b) => Number(new Big(a).add(Number(b.amount))),
    0
  );
  const inValid = orders.some(
    (item) =>
      Number(item.total) === 0 ||
      Number(item.amount) === 0 ||
      Number(item.total) < 0 ||
      Number(item.amount) < 0 ||
      !Number.isInteger(Number(item.total)) ||
      Number(item.amount) > 100
  );

  const handleConfirm = () => {
    if (isLoading || !total || !amount || inValid) return;
    const numbers = [];
    const prices = [];
    orders.forEach(item => {
      numbers.push(item.total);
      prices.push(Number(new Big(item.amount).mul(1e18)));
    })
    mutate({ numbers, prices, address });
  };

  const addOrder = () => {
    setOrders(v => [...v, { total: 0, amount: 0, id: uuid() }]);
  }

  const handleChange = (index, value, type) => {
    const arr = orders.map((item) => {
      if (item.id === index) {
        return {
          ...item,
          ...(type === "total" && { total: value }),
          ...(type === "price" && { amount: new Big(value || 0).mul(item.total).toString()}),
        };
      }
      return item;
    });
    setOrders(arr);
  }

  useEffect(() => {
    if (isSuccess) {
      notify("Create Success.");
      onSuccess();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      notify("Order Submitted.");
      // notify("Create Failed.");
      onSuccess();
    }
  }, [isError]);

  return (
    <Dialog width={400} title="Batch List" onClose={onClose} visible={visible}>
      <CardItem>
        {/* <OrderButton onClick={addOrder}>Add Order</OrderButton> */}
        <LeftInscriptions>{Number(nfts[1])} left inscriptions</LeftInscriptions>
      </CardItem>
      <CardItem>
        <CardLabel>floor price</CardLabel>
        <CardValue>{floorPrice} kcs/inscription</CardValue>
      </CardItem>
      {orders.map((order, idx) => (
        <OrderItem key={idx}>
          <Hr data-content={`order#${idx}`} />
          <CardItem>
            <InputContent>
              <InputLabel>total:</InputLabel>
              <InputWrapper>
                <InputContainer
                  type="number"
                  min={0}
                  onChange={(e) =>
                    handleChange(order.id, e.target.value, "total")
                  }
                  // value={order.total}
                />
                inscriptions
              </InputWrapper>
            </InputContent>
          </CardItem>
          <CardItem>
            <InputContent>
              <InputLabel>price:</InputLabel>
              <InputWrapper>
                <InputContainer
                  type="number"
                  min={0}
                  onChange={(e) =>
                    handleChange(order.id, e.target.value, "price")
                  }
                />
                kcs
              </InputWrapper>
            </InputContent>
          </CardItem>
        </OrderItem>
      ))}
      <Hr data-content="Settlement" />
      {total > 100 && (
        <CardItem>
          <CardLabel />
          <CardTip>The maximum number of listings is limited to 100</CardTip>
        </CardItem>
      )}
      <CardItem>
        <CardLabel>inscriptions total</CardLabel>
        <CardValue>{total}</CardValue>
      </CardItem>
      <CardItem>
        <CardLabel>order total</CardLabel>
        <CardValue>{amount} kcs</CardValue>
      </CardItem>
      <ButtonWrapper>
        <ListButton onClick={onClose}>Cancel</ListButton>
        <ListButton
          onClick={handleConfirm}
          disabled={total > Number(nfts[1]) || inValid || isLoading}
        >
          {isLoading ? "Creating..." : "Ok"}
        </ListButton>
      </ButtonWrapper>
    </Dialog>
  );
}
