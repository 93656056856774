import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAccount } from 'wagmi';
import InfiniteScroll from 'react-infinite-scroll-component';
import Table from '../components/Table';
import { useTokens } from '../hooks/useQuery';
import SearchImg from '../assets/search.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 155px;
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
`;

const Title = styled.div`
  color: var(--2, #1F1F1F);
  text-align: center;
  font-size: 44px;
  font-weight: 700;
  line-height: 52px; /* 118.182% */
  margin-bottom: 48px;
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 24px;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
`;

const Input = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  width: 400px;
  padding: 12px 20px;
  border-radius: 12px;
  border: 1px solid #1F1F1F;
  background: #fff;
`;

const InputContainer = styled.input`
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  text-indent: 20px;
  font-size: 24px;
`;

const Search = styled.img`
  width: 24px;
  height: 24px;
`;

const SearchButton = styled.div`
  display: flex;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #F3F3F3;
  color: #1F1F1F;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  border: 1px solid #1F1F1F;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow: scroll;
`;

const TableContent = styled.div`
  width: 100%;
  ${props => props.theme.breakpoints.down('sm')} {
    min-width: 800px;
  }
`;

const columns = [
  {
    title: '#',
    dataIndex: 'id',
  },
  {
    title: 'Token',
    dataIndex: 'tick',
  },
  {
    title: 'Protocol',
    dataIndex: 'kcs-20',
    render: () => 'kcs-20'
  },
  {
    title: 'Amt',
    dataIndex: 'amt',
  },
  {
    title: 'Inscription Content',
    dataIndex: 'content',
    width: 560,
  },
];

export default function Home() {
  const [search, setSearch] = useState('kcss');
  const [searchParam, setSearchParam] = useState('kcss');
  const [offset, setOffset] = useState(0);
  const { address } = useAccount();
  const { data } = useTokens(address, offset, searchParam);
  const [tokens, setTokens] = useState([]);

  const fetchMoreData = () => {
    setOffset(offset + 20);
  }

  const handleSearch = () => {
    setSearchParam(search);
  }

  useEffect(() => {
    if (data && data.tokens) {
      setTokens(_tokens => {
        // no data or same data
        if (!_tokens.length || (_tokens[0].tick === data.tokens[0].tick)) {
          return _tokens.concat(data.tokens);
        } else {
          return data.tokens;
        }
      });
    }
  }, [data])

  return (
    <Wrapper>
      <Title>My Tokens</Title>
      <InputWrapper>
        <Input>
          <Search src={SearchImg} />
          <InputContainer value={search} onChange={e =>  setSearch(e.target.value)} />
          <SearchButton onClick={handleSearch}>Search</SearchButton>
        </Input>
      </InputWrapper>
      <TableWrapper>
        <TableContent>
          <InfiniteScroll
            dataLength={tokens.length}
            next={fetchMoreData}
            hasMore={data && data.tokens.length >= 20}
            loader={<h4>Loading...</h4>}
          >
            <Table columns={columns} data={tokens.filter(item => item.valid)} pagination={{ total: 50, current: 1 }} />
          </InfiniteScroll>
        </TableContent>
      </TableWrapper>
    </Wrapper>
  );
}