import { useState } from "react";
import styled from "styled-components";
import Tabs from "../components/Tabs";
import { TAB_ITEMS } from "../config";
import MyNFTsList from "../components/MyNFTList";
import MyOrders from "../components/MyOrders";
import AllOrders from '../components/AllOrders';
import MintDialog from '../components/MintDialog';

const Wrapper = styled.div`
  dislpay: flex;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24px;
`;

export default function Market() {
  const [activeKey, setActiveKey] = useState("3");

  return (
    <Wrapper>
      <Title>Market Place</Title>
      <MintDialog />
      <Tabs activeKey={activeKey} items={TAB_ITEMS} onChange={setActiveKey} />
      {activeKey === "3" && <MyNFTsList />}
      {activeKey === "2" && <MyOrders />}
      {activeKey === "1" && <AllOrders />}
    </Wrapper>
  );
}
