import { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { switchNetwork } from '@wagmi/core'
import { useAccount, useNetwork, usePrepareSendTransaction, useSendTransaction } from 'wagmi';
import { notify, stringToHex } from '../utils';
import DeployImg from '../assets/deploy-img.svg';
import { kcc_chain_id } from '../config';
import useGas from "../hooks/useGas";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 155px;
`;

const Title = styled.div`
  color: var(--2, #1F1F1F);
  text-align: center;
  font-size: 44px;
  font-weight: 700;
  line-height: 52px; /* 118.182% */
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 24px;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 24px;
  padding: 32px 48px;
  display: flex;
  width: 775px;
  padding: 32px 48px;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  border: 1px solid #1F1F1F;
  background: #F3F3F3;
  box-shadow: 2px 2px 0px 4px #1F1F1F;
  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
    padding: 16px;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;    
  }
`;

const Input = styled.input`
  width: 100%;
  height: 64px;
  padding: 0 20px;
  outline: none;
  color: #1F1F1F;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.15px;
  border: 1px solid #1F1F1F;
  border-radius: 12px;
  background: transparent;
`;

const InputSupply = styled(Input)`
  border-radius: 12px;
  border: 1px solid #1F1F1F;
  background: #FFF;
  box-shadow: 2px 2px 0px 2px #1F1F1F;
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const InputItem = styled(Item)`
  width: 30%;
  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const MintLogo = styled.img`
  width: 267px;
  height: 105px;
`;

const Label = styled.div`
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1F1F1F;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
`;

const MintText = styled.div`
  width:100%;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #1F1F1F;
  color: #1F1F1F;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.15px;
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
`;

const Button = styled.div`
  margin-top: 64px;
  display: flex;
  width: 300px;
  height: 56px;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #1F1F1F;
  background: #C8FC7C;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export default function Home() {
  const [tick, setTick] = useState();
  const [max, setMax] = useState(21000000);
  const [limit, setLimit] = useState(1000);
  const { isConnected, address } = useAccount();
  const { chain } = useNetwork();
  // const { switchNetwork } = useSwitchNetwork();

  const netWorkError = !chain || chain.id !== kcc_chain_id;

  const handleTick = (e) => {
    setTick(e.target.value.trim());
  }

  const handleMax = (e) => {
    setMax(e.target.value.trim().replace('.', ''));
  }

  const handleLimit = (e) => {
    setLimit(e.target.value.trim().replace('.', ''));
  }

  const deployText = useMemo(() => {
    return { p: 'kcs-20', op: 'deploy', tick, max: String(max), lim: String(limit) };
  }, [tick, max, limit])

  const mintText = useMemo(() => {
    return { p: 'kcs-20', op: 'mint', tick, amt: String(limit) };
  }, [tick, limit])

  const disabled = !tick || Number(max) < 1 || Number(limit) < 1;

  const transactionParams = {
    to: address,
    value: 0,
    data: `0x${stringToHex(`data:,${JSON.stringify(deployText)}`)}`,
  };

  const gas = useGas(transactionParams);

  const { config } = usePrepareSendTransaction({ ...transactionParams, gas })
  const { sendTransaction, isLoading, isSuccess } = useSendTransaction(config)

  const handleDeploy = async () => {
    if (disabled || !isConnected) return;
    if (!gas) {
      notify("Gas cost estimation failed, please try again later.!");
      return;
    }
    if (netWorkError) {
      try {
        const _network = await switchNetwork({ chainId: kcc_chain_id });
        if (_network) {
          sendTransaction();
        }
      } catch (e) {
        notify("Switch Cancelled!");
      }
    } else {
      sendTransaction();
    }
  }

  useEffect(() => {
    if (isSuccess) {
      notify('Deploy Submitted!');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (netWorkError) {
      notify('Network Error!');
    }
  }, [netWorkError]);

  return (
    <Wrapper>
      <Title>KCS20 Deployer</Title>
      <ContentWrapper>
        <MintLogo src={DeployImg} />
        <InputWrapper>
          <InputItem>
            <Label>Tick</Label>
            <Input value={tick} maxLength={16} onChange={handleTick} />  
          </InputItem>
          <InputItem>
            <Label>Max supply</Label>
            <InputSupply value={max} type="number" maxLength={16} min={1} onChange={handleMax} />  
          </InputItem>
          <InputItem>
            <Label>Limit per mint</Label>
            <Input value={limit} type="number" maxLength={16} min={1} max={1000} onChange={handleLimit} />  
          </InputItem>
        </InputWrapper>
        <Item>
          <Label>Deploy Text</Label>
          <MintText>
            {JSON.stringify(deployText)}
          </MintText>
        </Item>
        <Item>
          <Label>Mint Text</Label>
          <MintText>
            {JSON.stringify(mintText)}
          </MintText>
        </Item>
        <Button className={clsx({ disabled: !isConnected || disabled })} onClick={handleDeploy}>
          { isLoading ? 'Deploying...' : 'Deploy' }
        </Button>
      </ContentWrapper>
    </Wrapper>
  );
}