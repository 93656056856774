import styled from 'styled-components';
import 'rc-pagination/assets/index.css';
import RcPagination from 'rc-pagination';

const Container = styled.div`
  margin: 24px 0;
  display: flex;
  justify-content: center;
`;

const PaginationWrapper = styled(RcPagination)`
  .rc-pagination-prev .rc-pagination-item-link, 
  .rc-pagination-next .rc-pagination-item-link,
  .rc-pagination-item {
    border-radius: 6px;
    font-size: 14px;
    a {
      color: rgba(29, 29, 29, 0.40);
    }
  }
  .rc-pagination-prev:focus .rc-pagination-item-link,
  .rc-pagination-next:focus .rc-pagination-item-link, 
  .rc-pagination-prev:hover .rc-pagination-item-link, 
  .rc-pagination-next:hover .rc-pagination-item-link {
    border: 1px solid #d9d9d9;
  }
  .rc-pagination-item-active,
  .rc-pagination-item:hover {
    border-color: #1F1F1F;
    a {
      color: #1D1D1D;
    }  
  }
  .rc-pagination-item:focus {
    border: 1px solid #d9d9d9;
    a {
      color: rgba(29, 29, 29, 0.40);
    }
  }
  .rc-pagination-item-active {
    border-color: #1F1F1F !important;
    a {
      color: #1D1D1D !important;
    }  
  }
  .rc-pagination-prev button:after,
  .rc-pagination-next button:after {
    font-size: 20px;
    transform: translateY(-2px);
    color: rgba(29, 29, 29, 0.40);
  }
`;

const PrevIconNode = styled.img``;

const NextIconNode = styled.img``;

export default function Pagination(props) {
  return (
    <Container>
      <PaginationWrapper {...props} />
    </Container>
  );
}