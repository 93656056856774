import { useMemo, useEffect } from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import clsx from 'clsx';
import { switchNetwork } from '@wagmi/core'
import Big from 'big.js';
import { useAccount, usePrepareSendTransaction, useSendTransaction, useNetwork } from 'wagmi';
import { notify, stringToHex } from '../utils';
import { kcc_chain_id } from '../config';
import { useInscriptionsWithParam } from '../hooks/useQuery';
import useGas from "../hooks/useGas";
import MintImg from '../assets/mint-img.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 155px;
`;

const Title = styled.div`
  color: var(--2, #1F1F1F);
  text-align: center;
  font-size: 44px;
  font-weight: 700;
  line-height: 52px; /* 118.182% */
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 24px;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 24px;
  padding: 32px 48px;
  display: flex;
  width: 775px;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  border: 1px solid #1F1F1F;
  background: #F3F3F3;
  box-shadow: 2px 2px 0px 4px #1F1F1F;
  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
    padding: 16px;
  }
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const MintLogo = styled.img`
  width: 267px;
  height: 105px;
`;

const Label = styled.div`
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1F1F1F;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
`;

const Text = styled.div`
  color: #1F1F1F;
  text-align: right;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.15px;
`;

const Progress = styled.div`
  width: 100%;
  height: 24px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid #1F1F1F;
  background: #FFF;
  overflow: hidden;
`;

const ProgressInner = styled.div`
  height: 100%;
  border-radius: 100px;
  border: 1px solid #1F1F1F;
  background: #C8FC7C;
`;

const MintText = styled.div`
  width:100%;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #1F1F1F;
  color: #1F1F1F;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.15px;
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
`;

const Button = styled.div`
  margin-top: 64px;
  display: flex;
  width: 300px;
  height: 56px;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #1F1F1F;
  background: #C8FC7C;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
   &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export default function Home() {
  const { address, isConnected } = useAccount();
  const { tick = 'kcss' } = qs.parse(window.location.search);
  const { chain } = useNetwork();
  const { data } = useInscriptionsWithParam(tick);
  const token = data && data.inscriptions[0] || {};

  const netWorkError = !chain || chain.id !== kcc_chain_id;

  const mintText = useMemo(() => {
    return { p: 'kcs-20', op: 'mint', tick: token.tick, amt: token.lim };
  }, [token])

  const disabled = !tick || Number(token.lim || 0) < 1;

  const transactionParams = {
    to: address,
    value: 0,
    data: `0x${stringToHex(`data:,${JSON.stringify(mintText)}`)}`,
  };

  const gas = useGas(transactionParams);

  const { config } = usePrepareSendTransaction({ ...transactionParams, gas })
  const { sendTransaction, isLoading, isSuccess } = useSendTransaction(config)

  const handleDeploy = async () => {
    if (!gas) {
      notify("Gas cost estimation failed, please try again later.!");
      return;
    }
    if (disabled || !isConnected) return;
    if (netWorkError) {
      try {
        const _network = await switchNetwork({ chainId: kcc_chain_id });
        if (_network) {
          sendTransaction();
        }
      } catch (e) {
        notify('Switch Cancelled!');
      }
    } else {
      sendTransaction();
    }
  }

  useEffect(() => {
    if (!token) {
      notify('Select one Token!');
    }
  }, [token]);

  useEffect(() => {
    if (isSuccess) {
      notify('Mint Submitted!');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (netWorkError) {
      notify('Network Error!');
    }
  }, [netWorkError]);

  const percent = useMemo(() => {
    if (disabled) return '0%';
    const _data = new Big(token.mint_total).div(new Big(token.max).div(token.lim)).times(100).toFixed(2);
    if (new Big(_data).gte(100)) {
      return '100%';
    }
    return _data.toString() + '%';
  }, [disabled, token]);

  return (
    <Wrapper>
      <Title>Mint Inscription</Title>
      <ContentWrapper>
        <MintLogo src={MintImg} />
        <Item>
          <Label>Tick</Label>
          <MintText>{tick}</MintText>
        </Item>
        <Item>
          <Label>
            Progress
            <Text>{percent}</Text>
          </Label>
          <Progress>
            <ProgressInner style={{ width: percent }} />
          </Progress>
        </Item>
        <Item>
          <Label>Mint Text</Label>
          <MintText>
            {JSON.stringify(mintText)}
          </MintText>
        </Item>
        <Button onClick={handleDeploy} className={clsx({ disabled: !isConnected || disabled })}>
          {isLoading ? 'Minting...' : 'Mint Now'}
        </Button>
      </ContentWrapper>
    </Wrapper>
  );
}