import { useState } from "react";
import { useAccount } from "wagmi";
import { page_size } from "../config";
import {
  Container,
  Card,
  CardItem,
  CardLabel,
  CardValue,
  CardButton,
  Content,
  CardImgWrapper,
  CardImg,
  BatchOrderButton,
  FloorPriceWrapper,
} from "./ListStyled";
import { getAddress } from '../utils';
import { useMyNFTs } from "../hooks/useQuery";
import CardPng from "../assets/card-img.jpeg";
import Pagination from "../components/Pagination";
import MakeOrder from "./MakeOrder";
import BatchMakeOrder from "./BatchMakeOrder";

export default function MyNFTsList() {
  const [visible, setVisible] = useState(false);
  const [batchVisible, setBatchVisible] = useState(false);
  const { address } = useAccount();
  const [page, setPage] = useState(1);
  const data = useMyNFTs(page);
  const [id, setId] = useState();

  const handleOpen = (id) => {
    setId(id);
    setVisible(true);
  }

  const handleCancel = () => {
    setVisible(false);
    setId(undefined);
  }

  return (
    <Container>
      <FloorPriceWrapper>My Inscriptions: {Number(data[1])}</FloorPriceWrapper>
      {data[0].length > 2 ? (
        <BatchOrderButton onClick={() => setBatchVisible(true)}>
          Batch Create Orders
        </BatchOrderButton>
      ) : null}
      <Content>
        {data[0].map((item, idx) => (
          <Card key={idx}>
            <CardItem>
              <CardLabel>inscription</CardLabel>
              <CardValue>#{Number(item)}</CardValue>
            </CardItem>
            <CardImgWrapper>
              <CardImg src={CardPng} />
            </CardImgWrapper>
            <CardItem>
              <CardLabel>owner</CardLabel>
              <CardValue>{getAddress(address)}</CardValue>
            </CardItem>
            <CardItem>
              <CardButton onClick={() => handleOpen(Number(item))}>
                List
              </CardButton>
            </CardItem>
          </Card>
        ))}
      </Content>
      <Pagination
        total={Number(data[1])}
        current={page}
        onChange={(page) => setPage(page)}
        pageSize={page_size}
      />
      <MakeOrder
        visible={visible}
        onClose={handleCancel}
        id={id}
        onSuccess={handleCancel}
      />
      <BatchMakeOrder
        visible={batchVisible}
        onClose={() => setBatchVisible(false)}
        onSuccess={() => setBatchVisible(false)}
      />
    </Container>
  );
}
