import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Big, { div } from 'big.js';
import Table, { TableAction } from '../components/Table';
import { useInscriptions } from '../hooks/useQuery';
import { formatNumber } from '../utils';
import SearchImg from '../assets/search.svg';
import KCCImg from '../assets/kcc-logo.svg';
import TableArrowImg from '../assets/table-arrow.svg';
import SignImg from '../assets/sign.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 155px;
`;

const Title = styled.div`
  color: var(--2, #1F1F1F);
  text-align: center;
  font-size: 44px;
  font-weight: 700;
  line-height: 52px; /* 118.182% */
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 24px;
  }
`;

const Input = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  width: 775px;
  padding: 12px 20px;
  border-radius: 12px;
  border: 1px solid #1F1F1F;
  background: #fff;
`;

const InputContainer = styled.input`
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  text-indent: 20px;
  font-size: 24px;
`;

const Search = styled.img`
  width: 24px;
  height: 24px;
`;

const SearchButton = styled.div`
  display: flex;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  opacity: 0.4;
  background: #F3F3F3;
  color: #1F1F1F;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  border: 1px solid #1F1F1F;
`;

const KCCSupportWrapper = styled.div`
  margin: 32px 0 24px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1F1F1F;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
`;

const KCCSupport = styled.a`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #1F1F1F;
  color: #1F1F1F;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.15px;
  cursor: pointer;
  text-decoration: none;
  ${props => props.theme.breakpoints.down('sm')} {
    display: none;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const KCCLogo = styled.img`
  width: 50.70px;
  height: 24px;
  margin-right: 12px;
`;

const TableArrow = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow: scroll;
`;

const TableContent = styled.div`
  width: 100%;
  ${props => props.theme.breakpoints.down('sm')} {
    min-width: 800px;
  }
`;

const TickWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SignLogo = styled.img`
  width: 32px;
  height: 32px;
  margin-left: 8px;
`;

export default function Home() {
  const { data } = useInscriptions();
  const push = useNavigate();

  const columns = [
    {
      title: "Token",
      dataIndex: "tick",
      render: (a) => a === 'kcss' ? (
        <TickWrapper>
          {a}
          <SignLogo src={SignImg} />
        </TickWrapper>
      ) : a,
    },
    {
      title: "Protocol",
      dataIndex: "protocol",
      render: (a) => a || "kcs-20",
    },
    {
      title: "Total Supply",
      dataIndex: "max",
      render: (a) => formatNumber(a),
    },
    {
      title: "Minted %",
      dataIndex: "mint_total",
      render: (a, b) => {
        const _data = new Big(a)
          .div(new Big(b.max).div(b.lim))
          .times(100)
          .toFixed(2);
        if (new Big(_data).gte(100)) {
          return "100%";
        }
        return _data.toString() + "%";
      },
    },
    {
      title: "Mint Limit",
      dataIndex: "lim",
      render: (a) => formatNumber(a),
    },
    {
      title: "Holders",
      dataIndex: "user_total",
      render: (a) => (a ? formatNumber(a) : 0),
    },
    {
      title: "",
      dataIndex: "",
      render: (a, b) => (
        <TableAction onClick={() => push(`/inscription?tick=${b.tick}`)}>
          <TableArrow src={TableArrowImg} />
        </TableAction>
      ),
    },
  ];

  return (
    <Wrapper>
      <Title>Explore Tokens</Title>
      {/* <Input>
        <Search src={SearchImg} />
        <InputContainer />
        <SearchButton>Search</SearchButton>
      </Input> */}
      <KCCSupportWrapper>
        Token list
        <KCCSupport href="https://www.kcc.io" target='_blank'>
          <KCCLogo src={KCCImg} />
          Supported by KCC
        </KCCSupport>
      </KCCSupportWrapper>
      <TableWrapper>
        <TableContent>
          <Table columns={columns} data={data && data.inscriptions || []} pagination={{ total: data && data.inscriptions.length || 0 }} />
        </TableContent>
      </TableWrapper>
    </Wrapper>
  );
}