import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { estimateGas } from '../utils';

export default function useGas(data) {
  const [gas, setGas] = useState();
  const { address } = useAccount();

  useEffect(() => {
    async function getGas() {
      const _gas = await estimateGas({ ...data, from: address });
      setGas(_gas);
    }
    if (!address) return;
    getGas();
  }, [data, address]);
  
  return gas;
}