import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Connect from './Connect';
import TokensImg from '../assets/tokens.svg';
import InscriptionImg from '../assets/inscription.svg';
import DeployImg from '../assets/deploy.svg';
import MyTokenImg from '../assets/my-token.svg';
import TokensImgWhite from '../assets/tokens-white.svg';
import InscriptionImgWhite from '../assets/inscription-white.svg';
import DeployImgWhite from '../assets/deploy-white.svg';
import MyTokenImgWhite from '../assets/my-token-white.svg';
import NetWork from './NetWork';

const Wrapper = styled.div`
  height: 80vh;
  padding: 32px 12px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  z-index: 2;
`;

const MenuItem = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  font-size: 18px;
  color: #1F1F1F;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.15px;
  border-radius: 8px;
  cursor: pointer;
  &.active {
    color: #fff;
    background: #1F1F1F;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const MenuIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MenusWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

function Links({ toggleVisible }) {
  const path = window.location.pathname;
  const push = useNavigate();

  const handlePush = (pathname) => {
    push(pathname);
    toggleVisible();
  }

  return (
    <MenusWrapper>
      <MenuItem className={clsx({ active: path === '/' })} onClick={() => handlePush('/')}>
        <MenuIcon src={ path === '/' ? TokensImgWhite : TokensImg} />
        Tokens
      </MenuItem>
      <MenuItem className={clsx({ active: path === '/inscription' })} onClick={() => handlePush('/inscription')}>
        <MenuIcon src={path === '/inscription' ? InscriptionImgWhite : InscriptionImg} />
        Inscription
      </MenuItem>
      {/* <MenuItem className={clsx({ active: path === '/deploy' })} onClick={() => handlePush('/deploy')}>
        <MenuIcon src={path === '/deploy' ? DeployImgWhite : DeployImg} />
        Deploy KCS20
      </MenuItem> */}
      {/* <MenuItem className={clsx({ active: path === '/mine' })} onClick={() => handlePush('/mine')}>
        <MenuIcon src={path === '/mine' ? MyTokenImgWhite : MyTokenImg} />
        My tokens
      </MenuItem> */}
    </MenusWrapper>
  );
}

export default function HeaderDrawer({ visible, toggleVisible }) {
  return visible ? (
    <Wrapper>
      <LinkWrapper>
        <NetWork />
        <Connect />    
        <Links toggleVisible={toggleVisible} />
      </LinkWrapper>
    </Wrapper>
  ) : null;
}