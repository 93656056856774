import { createPublicClient, custom } from "viem";
import { mainnet } from "viem/chains";
import { toast } from "react-toastify";
import Web3 from "web3";
import { 
  market_contract_address, 
  assets_contract_address,
  state_contract_address,
  token_convert_address,
} from "../config";
import assets_abi from '../config/assets_abi.json';
import kcss_state_abi from "../config/kcss_state_abi.json";
import market_abi from "../config/market_abi.json";
import token_convert_abi from "../config/token_convert_abi.json";

export const web3 = new Web3(window.ethereum || "https://kcc-rpc.com/");

export const accounts = await web3.eth.getAccounts();
export const Market = new web3.eth.Contract(market_abi, market_contract_address);
export const Assets = new web3.eth.Contract(assets_abi, assets_contract_address);
export const KCSSState = new web3.eth.Contract(kcss_state_abi, state_contract_address);
export const TokenConvert = new web3.eth.Contract(
  token_convert_abi,
  token_convert_address
);

let client;
if (window.ethereum) {
 client = createPublicClient({
  chain: mainnet,
  transport: custom(window.ethereum),
});
}

export const addMainNet = async () => {
  if (!window.ethereum) return;
  await window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: `0x${(321).toString(16)}`,
        chainName: "KCC Mainnet",
        nativeCurrency: {
          name: "KCCMainnet",
          symbol: "KCS",
          decimals: 18,
        },
        rpcUrls: ["https://rpc-mainnet.kcc.network"],
        blockExplorerUrls: ["https://explorer.kcc.io/en"],
      },
    ],
  });
};

export const notify = (msg) => toast(msg);

export const stringToHex = (str) => {
  let hex = "";
  for (let i = 0; i < str.length; i++) {
    hex += str.charCodeAt(i).toString(16).padStart(2, "0");
  }
  return hex;
};

export const formatNumber = (num) => new Intl.NumberFormat("en-US").format(num);

export const estimateGas = async ({ from, to, ...props }) => {
  return await client.estimateGas({
    account: from,
    to,
    ...props,
  });
};

export const getGasAmount = async (fromAddress, toAddress, value) => {
  const gasAmount = await web3.eth.estimateGas({
    to: toAddress,
    from: fromAddress,
    value: value || 0,
  });
  return gasAmount;
};

export const getGasPrice = async () => {
  const gasPrice = await web3.eth.getGasPrice();
  return gasPrice
};

export const getAddress = (owner) => {
  if (!owner) return "";
  const _first = owner.slice(0, 6);
  const _last = owner.slice(-3);
  return `${_first}...${_last}`;
};