export const kcc_chain_id = 321;

export const state_contract_address = "0xF7CC6E7382822649b60d833Ee4fc051aDf4755C1";
export const assets_contract_address = "0x9305Aba35b54E593fC417fc6A4c1e20691d665c8";
export const market_contract_address = "0x2808A917605AAF8E9ce2fd057475f58472B46341";

export const token_convert_address = "0xA6EdBb50d1Fcf8ce6b6A1DC08e83F0ffAD9C57CB";

export const zero_address = "0x0000000000000000000000000000000000000000";

export const page_size = 20;

export const decimals = 1e18;

export const mint_fee = 0.0005;

export const TAB_ITEMS = [
  {
    key: "1",
    label: "Listed",
  },
  {
    key: "2",
    label: "My List",
  },
  {
    key: "3",
    label: "My Inscriptions",
  },
];