import { useEffect, useState } from "react";
import { Market } from "../utils";
import Big from "big.js";

export default function useFloorPrice() {
  const [floorPrice, setFloorPrice] = useState(0);

  useEffect(() => {
    async function initEvent() {
      const events = await Market.getPastEvents("EVENT_BuyOrder", {
        filter: {},
        fromBlock: 0,
        toBlock: "latest",
      });
      const lastOne = events.pop();
      if (!lastOne) return;
      const { returnValues } = lastOne;
      const { amount, nftCount } = returnValues;
      const floorPrice = new Big(Number(amount)).div(Number(nftCount)).div(1e18).toString();
      setFloorPrice(floorPrice);
    }
    initEvent();
  }, []);

  return floorPrice;
}
