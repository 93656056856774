import styled from 'styled-components';

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #1F1F1F;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 27px 0 53px 0;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
`;

export default function Footer() {
  return (
    <>
      <Divider />
      <Wrapper>
        KCSription - The First Inscription Protocol on Kucoin chain!
      </Wrapper>
    </>
  );
}