import { useAccount } from "wagmi";
import { request, gql } from "graphql-request";
import { useQuery } from "react-query";
import { zero_address, page_size } from "../config";
import {
  getOrdersWithPage,
  getNFTsWithPage,
  lockedInscriptions,
  getMintPossible,
} from "../services";

const endpoint = "https://kcscription.com/2graphql/";

const TOKENS_QUERY = (owner, offset, search) => gql`
  {
    tokens(owner: "${owner}", offset: "${offset}", tick: "${search}") {
      id,
      tick,
      amt,
      valid,
      hash,
      content,
    }
  }
`;

const INSCRIPTIONS_QUERY = gql`
  {
    inscriptions {
      id
      creator
      tick
      lim
      max
      hash
      user_total
      mint_total
      block_number
    }
  }
`;

const INSCRIPTIONS_QUERY_WITHPARAM = (tick) => gql`
  {
    inscriptions(tick: "${tick}") {
      id,
      creator,
      tick,
      lim,
      max,
      hash,
      user_total,
      mint_total,
      block_number,
    }
  }
`;

export const useInscriptions = () => {
  return useQuery({
    queryKey: ["inscriptions"],
    queryFn: () => {
      return {
        inscriptions: [
          {
            tick: "kcss",
            protocol: "kcs-20",
            max: "15000000000",
            mint_total: "15000000",
            lim: "1000",
            user_total: "6116",
          },
        ],
      };
    },
    initialData: {
      inscriptions: []
    },
  });
};

export const useInscriptionsWithParam = (tick = "kcss") => {
  return useQuery(["inscriptions", tick], () => {
    if (tick === 'kcss') {
      return {
        inscriptions: [
          {
            tick: "kcss",
            protocol: "kcs-20",
            max: "15000000000",
            mint_total: "15000000",
            lim: "1000",
            user_total: "6116",
          },
        ],
      };
    }
    return request(endpoint, INSCRIPTIONS_QUERY_WITHPARAM(tick));
  }
  );
};

export const useTokens = (owner, offset, search = "kcss") => {
  return useQuery(["tokens", owner, offset, search], () =>
    request(
      endpoint,
      TOKENS_QUERY(String(owner).toLowerCase(), offset, search || "kcss")
    )
  );
};

export const useAllOrders = (page = 1) => {
  const { address } = useAccount();
  return useQuery({
    queryKey: ['allOrders', page, address],
    queryFn: () =>
      getOrdersWithPage({ address: zero_address, pageSize: page_size, page }),
    initialData: { 0: [], 1: 0 },
  }).data;
}

export const useMyOrders = (page = 1) => {
  const { address } = useAccount();
  return useQuery({
    queryKey: ['myOrders', page, address],
    queryFn: () =>
      getOrdersWithPage({ address, pageSize: page_size, page }),
    initialData: { 0: [], 1: 0 },
  }).data;
};

export const useMyNFTs = (page = 1) => {
  const { address } = useAccount();
  return useQuery({
    queryKey: ["myNFTs", page, address],
    queryFn: () => getNFTsWithPage({ address, pageSize: page_size, page }),
    initialData: { 0: [], 1: 0 },
  }).data;
};

export const useLockedInscriptions = (mintPossible) => {
  const { address } = useAccount();
  return useQuery({
    queryKey: ["lockedInscriptions", mintPossible],
    queryFn: () => lockedInscriptions(address),
    initialData: 0,
  }).data;
};

export const useMintPossible = () => {
  const { address } = useAccount();
  return useQuery({
    queryKey: ["mintPossible", address],
    queryFn: () => getMintPossible(address),
    initialData: 0,
  }).data;
}

export const useFloorPrice = () => {
  
}