import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  dislay: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
`;


export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  padding: 12px;
  margin-bottom: 24px;
  margin-right: 32px;
  border: 1px solid #000;
  border-radius: 8px;
  background: #fff;
  &:nth-child(4) {
    margin-right: 0;
  }
  &:hover {
    box-shadow: 2px 2px 2px gray;
  }
`;

export const BatchOrderButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
  border: none;
  outline: none;
  width: 200px;
  height: 36px;
  border-radius: 8px;
  color: #000;
  background: #ddd;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &[disabled] {
    opacity: 0.4;
  }
`;

export const CardLabel = styled.div``;
export const CardValue = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const PriceValue = styled(CardValue)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const UnitValue = styled.div`
  font-size: 12px;
`;

export const CardImg = styled.img`
  width: 100%;
  height: auto;
`;

export const CardButton = styled.button`
  border: none;
  outline: none;
  width: 100%;
  height: 36px;
  border-radius: 8px;
  color: #000;
  background: #ddd;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &[disabled] {
    opacity: 0.4;
  }
`;

export const CardItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const CardTip = styled.div`
  font-size: 12px;
  color: #e25858;
`;

export const CardImgWrapper = styled(CardItem)`
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  margin-top: 24px;
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #f7f2f2;
`;

export const ListButton = styled(CardButton)`
  width: 120px;
  margin-left: 12px;
`;

export const ListInput = styled.input`
  width: 160px;
  height: 30px;
  margin-right: 4px;
  border: 1px solid #d4d3d3;
  outline: none;
  border-radius: 8px;
  text-indent: 12px;
`;

export const OrderButton = styled.a`
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const Hr = styled.hr`
  color: #a2a9b6;
  border: 0;
  font-size: 12px;
  padding: 1em 0;
  position: relative;
  &:before {
    content: attr(data-content);
    position: absolute;
    padding: 0 1ch;
    line-height: 1px;
    border: solid #d0d0d5;
    border-width: 0 99vw;
    width: fit-content;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const LeftInscriptions = styled.div`
  font-size: 14px;
`;

export const FloorPriceWrapper = styled.div`
  min-width: 500px;
  display: flex;
  margin: 24px 0;
  width: 36px;
  font-size: 16px;
`;